<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10 lg8 xl6>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-google-maps</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varLocalLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varLocalLocal.codigo"
                    label="Código"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="4" align-self="center">
                  <v-radio-group
                    dense
                    v-model="varLocalLocal.tipo"
                    row
                    mandatory
                  >
                    <v-radio label="Alfândega" value="alfandega"></v-radio>
                    <v-radio label="Porto" value="porto"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="12" align-self="center">
                  <v-textarea
                    dense
                    hide-details="auto"
                    v-model="varLocalLocal.local"
                    label="Local"
                    rows="2"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("cadLocais");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varLocalLocal: {
  //     nome_local: { required },
  //     email: { required, email },
  //     local: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "CadLocaisForm",
    tituloForm: "",
    varLocalLocal: {},
  }),

  computed: {
    ...mapState(["staLocalSelect", "listaUsuarios"]),
  },

  watch: {
    staLocalSelect(localNovo) {
      this.metSincronizar(localNovo);
    },
  },

  created() {
    this.metSincronizar(this.staLocalSelect);
    if (this.staLocalSelect) {
      this.tituloForm = "Editar Local";
    } else {
      this.tituloForm = "Nova Local";
    }
    // this.formataDatasDB();
  },

  methods: {
    ...mapActions([
      "actSelecionarLocal",
      "actResetarLocal",
      "actCriarLocal",
      "actEditarLocal",
      "actResetarErro",
    ]),

    /* obterUsuario(id) {
      if (id != null) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        return ret[0].usuario;
      } else {
        const ret = "";
        return ret;
      }
    },



    }, */

    selecionarLocalEdicao(local) {
      this.actSelecionarLocal({ local });
    },

    async salvarLocal() {
      switch (this.staLocalSelect) {
        case undefined:
          this.varLocalLocal.criacao = this.$store.state.login.usuario.usuario;
          this.varLocalLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          await this.actCriarLocal({
            local: this.varLocalLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.cadLocais.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadLocais.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Local";
            this.$root.snackbar.show({
              type: "success",
              message: "Local Criada!",
            });
          }
          break;
        default:
          this.varLocalLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varLocalLocal.criado = undefined;
          this.varLocalLocal.criacao = undefined;
          this.varLocalLocal.modificado = undefined;
          await this.actEditarLocal({
            local: this.varLocalLocal,
            licenca: this.$store.state.login.licenca,
          });
          // this.formataDatasDB();
          if (this.$store.state.cadLocais.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadLocais.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Local Salva!",
            });
          }
      }
      //this.resetar();
    },

    metSincronizar(local) {
      this.varLocalLocal = Object.assign({}, local || { id: null });
    },
    submit() {
      // this.$v.$touch();
      this.salvarLocal();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varLocalLocal.ultima_compra = this.staLocalSelect.ultima_compra;
      // this.varLocalLocal.email = this.staLocalSelect.email;
      // this.varLocalLocal.local = this.staLocalSelect.local;
      // this.varLocalLocal.senha = this.staLocalSelect.senha;
      // this.varLocalLocal.ativo = this.staLocalSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
